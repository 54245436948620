@import "../base/variables.scss";
@import "../mixins/breakpoint";
@import "../base/utilities";

.rsvp {

    &-left {
        // background-image: url("/images/RSVP-image.jpg");
        background-image: url("/images/tgi-event-1.png");

        background-size: contain;
        background-repeat: no-repeat;        
        background-attachment: fixed;


        @include media-breakpoint-down("md") {
            display: none;
        }

        @include media-breakpoint-up("xxl") {
            height: 100vh;
        }
    }

    &-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 90px !important;
        padding-right: 90px !important;

        // padding-top: 50px;
        // padding-bottom: 27px;
        @include media-breakpoint-down("md") {
            padding-left: 15px !important;
            padding-right: 15px !important;
        }

        &-logo {
            img {
                object-fit: contain;
            }

            @include media-breakpoint-down("md") {
                img {
                    width: 175px;
                    height: 80px;
                }

            }
        }

        &-title {
            padding-top: 22px;
            padding-bottom: 22px;
            font-size: 30px;
            font-weight: 500;
            line-height: 46px;

            @include media-breakpoint-down("md") {
                font-size: 24px;
                font-weight: 500;
                line-height: 35px;
            }
        }

        &-textField {
            margin-top: 12px;

            @include media-breakpoint-down("md") {
                margin-top: 5px;
            }

            input {
                width: 100%;
                height: 60px;
                border-radius: 20px;
                border: 1.5px solid #282D32;
                padding-left: 30px;
            }
        }

        &-button {
            width: 160px;
            height: 56px;
            border-radius: 20px;
            font-size: 18px;
            line-height: 27px;
            font-weight: 500;
            margin-top: 28px;
        }

        &-alertText {
            margin-top: 16px;
            font-size: 18px;
            line-height: 27px;

            @include media-breakpoint-down("md") {
                font-size: 16px;
                line-height: 25px;
            }

        }

        &-radioGroup {
            margin-top: 28px;
            display: flex;
            flex-direction: column;
            gap: 30px;

            input {
                margin-right: 30px;
                transform: scale(1.5);
            }
        }

        &-eventSaved {

            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            height: 100vh;

            &-logo {
                img {
                    object-fit: contain;
                }

                @include media-breakpoint-down("md") {
                    img {
                        width: 195px;
                        height: 85px;
                    }

                }
            }

            &-text {
                margin-top: 10px;
                font-size: 30px;
                line-height: 46px;
                font-weight: 500;

                @include media-breakpoint-down("md") {
                    font-size: 24px;
                    line-height: 35px;
                }
            }

            &-icon {
                margin-top: 18px;

                svg {
                    width: 85px;
                    height: 55px;
                }
            }
        }
    }

    .errorMessageContainer {
        height: 20px; // Reserve space for error message

        .errorMessage {
            color: red;
            font-size: 12px;
            padding-left: 20px;
        }
    }
}