.homeContainer {
    min-height: calc(100vh - 155px);
    padding: 14px 32px 48px 32px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    align-items: center;

    @media screen and (max-width: 1100px) {
        height: auto;
        overflow: initial;
        padding: 0 24px;
    }

    position: relative;

    .eclipseLeft {
        position: absolute;
        bottom: 7%;
        left: -7%;
        width: 350px;
        height: 350px;
        background-color: $color-system-pink;
        border-radius: 50%;

        @media screen and (max-width: 1460px) {
            width: 260px;
            height: 260px;
        }

        @media screen and (max-width:1300px) {
            width: 220px;
            height: 220px;
        }

        @media screen and (max-width:1100px) {
            display: none;
        }
    }

    .eclipseRight {
        position: absolute;
        top: 7%;
        right: -12%;
        width: 350px;
        height: 350px;
        background-color: $color-system-blue;
        border-radius: 50%;

        @media screen and (max-width: 1460px) {
            width: 260px;
            height: 260px;
        }

        @media screen and (max-width:1300px) {
            width: 220px;
            height: 220px;
        }

        @media screen and (max-width:1100px) {
            display: none;
        }
    }

    .singleLayout {
        max-width: 917px;
        width: 100%;
        margin: 0 auto;

        &.type1 {
            max-width: 1500px;
        }

        &.type2 {
            max-width: 1095px;
        }

        &.fullWidth {
            max-width: none;
        }

        &.theGenderIndex {
            align-self: flex-start;
        }
    }

    .doubleLayout {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;

        @media screen and (max-width: 1100px) {
            flex-direction: column;
        }

        .flexDv {
            width: 100%;

            @media screen and (max-width: 1100px) {
                width: 100%;
            }

            &+.graphicsDv {
                @media screen and (max-width: 1100px) {
                    margin-top: 32px;
                    margin-bottom: 16px;
                }
            }
        }

        .graphicsDv {
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                max-width: 100%;
                max-height: 500px;
                object-fit: contain;
            }
        }

        &.tgi-header-container {
            max-width: 1500px;
            margin: 0 auto;
        }
    }

    .multipleLayoutWrap {
        width: 100%;

        .resourcePage+.newsletterPage {
            margin-top: 150px;
        }
    }

    .heroBanner {
        background-color: #f1f1f1;
        height: 368px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px 0 30px 0;
        border-radius: 6px;

        @media screen and (max-width: 767px) {
            height: 200px;
        }

        img {
            max-width: 100%;
            height: 368px;
            border-radius: 6px;
            width: 100%;
            object-fit: cover;

            @media screen and (max-width: 767px) {
                height: 200px;
            }
        }

        span {
            font-size: 1.5rem;
            color: $color-system-black;
        }
    }

    .tabsNavigation {
        margin: 78px 0;

        @media screen and (max-width: 767px) {
            margin: 36px 0;
        }

        ul {
            display: flex;
            justify-content: space-around;
            align-items: center;
            gap: 16px;

            @media screen and (max-width: 1400px) {
                gap: 36px;
            }

            @media screen and (max-width: 1100px) {
                gap: 8px;
            }

            li {
                padding: 0 2px;

                @media screen and (max-width: 1400px) {
                    text-align: center;
                }

                a {
                    display: inline-block;
                    font-size: 1.25rem;
                    line-height: 30px;
                    color: $color-system-black;
                    padding-bottom: 4px;

                    @media screen and (max-width: 1400px) {
                        font-size: 14px;
                        line-height: normal;
                    }

                    @media screen and (max-width: 1100px) {
                        font-size: 14px;
                        line-height: normal;
                    }
                }

                &:hover {
                    border-bottom: 2px solid $color-system-red-primary;
                }

                &.active {
                    border-bottom: 2px solid $color-system-red-primary;

                    a {
                        font-weight: $fontWeight600;
                    }
                }
            }
        }
    }
}

.actionBtnsWrap {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 40px;

    button {
        min-width: 252px;

        @media screen and (max-width: 1100px) {
            min-width: auto;
            // font-size: 11px;
            // padding: 5.5px 8px;
            font-size: 12px;
            padding: 10px 18px;
        }

        svg {
            @media screen and (max-width: 400px) {
                display: none;
            }
        }
    }
}

.actionBtns {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 40px;

    button {
        min-width: 190px;
        padding: 10px;
        @media screen and (max-width: 1100px) {
            min-width: auto;
            font-size: 12px;
            padding: 10px 18px;
        }

        svg {
            @media screen and (max-width: 400px) {
                display: none;
            }
        }
    }
}

.landingPage {
    h1 {
        // margin-top: 40px;

        @media screen and (max-width: 1100px) {
            font-size: 30px;
            margin-top: 16px;
        }
    }

    .divider-red {
        width: 94%;
        height: 9px;
        background-color: $color-system-red-primary;
        border-radius: 10px;
        margin: 40px 0;

        @media screen and (max-width: 1100px) {
            margin: 20px 0;
        }
    }

    &+.singleLayout {
        padding-top: 27px;

        h2 {
            text-align: center;
            font-weight: $fontWeight500;
            margin: 73px 0;

            @media screen and (max-width: 767px) {
                font-size: 1.3rem;
                font-weight: $fontWeight600;
                margin: 20px 0;
            }
        }

        .supportersLogos {
            display: grid;
            grid-gap: 1.2rem;
            grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

            li {
                // background-color: rgba(0, 167, 183, 0.09);
                height: 116px;
                display: flex;
                border-radius: 10px;

                a {
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                }

                img {
                    height: 40px;
                }

                .nat-logo-home {
                    height: auto;
                    margin-top: -33px;
                    object-fit: contain;
                }
            }
        }

        .action {
            .btn-pink {
                min-width: 130px;
            }
        }
    }
}

.ambitionPage {
    h3 {
        margin-bottom: 40px;
    }

    h6 {
        margin-bottom: 16px;
    }

    p {
        margin-bottom: 16px;
    }
}

.reportPage {
    h3 {
        text-align: center;
        margin-bottom: 40px;

        @media screen and (max-width: 767px) {
            margin-bottom: 24px;
        }
    }

    p {
        margin-bottom: 16px;
    }

    .tabsNavigation {
        @media screen and (max-width: 767px) {
            margin: 24px 0;
        }

        ul {
            justify-content: space-evenly;
        }
    }

    .reportList {
        margin-bottom: 48px;

        li {
            display: flex;
            gap: 71px;
            margin-bottom: 100px;

            .reportCover {
                width: 40%;
            }

            @media screen and (max-width: 767px) {
                flex-direction: column;
                margin-bottom: 48px;
                gap: 36px;

                .reportCover {
                    width: 100%;
                }
            }

            img {
                max-width: 320px;
                object-fit: contain;

                @media screen and (max-width: 767px) {
                    margin: 0 auto;
                    max-width: 100%;
                    width: 100%;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
            .reportDetail {
                h2 {
                    margin-bottom: 72px;
                }
            }
        }
    }

    .narrativeList {
        li {
            display: flex;
            gap: 71px;
            margin-bottom: 100px;

            @media screen and (max-width: 767px) {
                flex-direction: column;
                margin-bottom: 48px;
                gap: 36px;
            }

            img {
                max-width: 295px;

                @media screen and (max-width: 767px) {
                    margin: 0 auto;
                    max-width: 100%;
                }
            }

            .reportDetail {
                h2 {
                    margin-bottom: 72px;
                }
            }
        }
    }

    .actionBtnsWrap {
        @media screen and (max-width: 767px) {
            a {
                width: 100%;

                button {
                    width: 100%;
                }
            }

            margin-top: 20px;
        }
    }
}

.resourcePage {
    .backLink {
        text-align: left;
        margin-bottom: 60px;

        @media screen and (max-width: 767px) {
            margin-bottom: 24px;
        }
    }

    .title {
        font-size: 1.875rem;
        font-style: normal;
        font-weight: 600;
        color: #282D32;
        // margin-bottom: 60px;
    }

    .heroImage {
        width: 100%;
        height: auto;
        object-fit: contain;
    }

    .markdown {
        margin-top: 40px
    }

    .tabsNavigation {
        margin: 78px 0 98px 0;
    }

    .heroBanner {
        margin: 20px 0 50px 0;
    }

    p {
        margin-bottom: 40px;

        &.dateText {
            font-size: 1.25rem;
            line-height: 30px;
            color: #707070;
        }
    }
}

.resourceDetailPage {

    h3,
    h5 {
        margin-bottom: 20px;
    }

    p {
        margin-bottom: 16px;
    }
}

.newsletterPage {
    p {
        margin: 32px 0;
    }

    form {
        max-width: 455px;
    }

    .actionBtnsWrap {
        margin-top: 25px;

        button {
            min-width: 180px;
        }
    }
}

.partnersPage {
    h3 {
        margin-bottom: 40px;
        text-align: center;

        @media screen and (max-width: 767px) {
            margin-bottom: 15px;
        }
    }

    .tabsNavigation {
        ul li {
            @media screen and (max-width: 767px) {
                padding: 0;
            }

            a {
                @media screen and (max-width: 767px) {
                    font-size: 11px;
                }
            }
        }
    }

    .partnerBanner {
        // max-width: 556px;
        // margin: 127px auto 100px auto;
        margin: 0 auto;

        @media screen and (max-width: 767px) {
            margin: 36px auto 48px auto;
            max-width: 100%;
        }

        a:hover,
        a:focus {
            figure {
                display: block;
                border: 1px solid #E5E5E5;
                box-shadow: 8px 8px 16px rgb(51 51 51 / 10%);
                border-radius: 4px;
                padding: 2px;
            }
        }

        &.natwest {
            img {
                height: auto;
                width: 150px;
                object-fit: contain;
            }
        }

        figure {
            width: 100%;
            height: 151px;
            margin: 0;
            margin-bottom: 32px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;

            @media screen and (max-width: 767px) {
                margin-bottom: 24px;
                //      height: 80px;
            }

            img {
                // height: 100%;
                max-height: 100%;
                max-width: 100%;
                object-fit: contain;
            }
        }

        button {
            // display: table;
            // margin-inline: auto;
            // margin-top: 60px;
            display: flex;
            justify-content: center;
            margin: 0 auto;
            margin-top: 80px;
            margin-bottom: 80px;
        }

        &.type2 {
            display: flex;
            justify-content: space-between;
            gap: 72px;
            // max-width: 650px;
            margin-left: -15px;
            margin-right: -15px;

            @media screen and (max-width: 767px) {
                gap: 16px;
            }

            >div {
                flex: 1;

                .btn-green {
                    @media screen and (max-width: 767px) {
                        padding: 10px 16px;
                    }
                }
            }

            figure {
                height: 142px;
            }
        }
    }

    .associatesList {
        li {
            display: flex;
            gap: 80px;
            margin-bottom: 48px;

            @media screen and (max-width: 767px) {
                flex-direction: column;
                gap: 16px;
            }

            figure {
                margin: 0;
                min-width: 110px;

                &:focus,
                &:hover {
                    display: block;
                    border: 1px px solid #E5E5E5;
                    box-shadow: 8px 8px 16px rgba(51, 51, 51, 0.1);
                    border-radius: 4px;
                }

                img {
                    width: 100px;
                }
            }

            .infoWrap {
                h6 {
                    margin-bottom: 32px;

                    @media screen and (max-width: 767px) {
                        margin-bottom: 16px;
                    }
                }
            }
        }
    }
}

.partnerDetailsPage {
    .edgeBannerWrapper {
        position: relative;
        height: 442px;
        width: 100vw;
        margin-bottom: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: 767px) {
            height: 350px;
        }

        .edgeBanner {
            background: url('../../../assets/img/detail.png') no-repeat center center;
            background-size: cover;
            height: 442px;
            position: absolute;
            width: 150%;
            left: -26%;

            @media screen and (max-width: 767px) {
                height: 350px;
            }
        }
    }

    .bannerDetailsWrap {
        position: relative;
        z-index: 2;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        max-width: 917px;

        @media screen and (max-width: 767px) {
            //    flex-direction: column;
            max-width: 90%;
        }

        @media screen and (max-width: 500px) {
            flex-direction: column;
            align-items: flex-start;
            gap: 48px;
        }

        .leftPane {
            .backBtn {
                color: $color-system-white;
            }

            h4 {
                font-size: 1.5rem;
                color: $color-system-white;
                margin-block: 16px;

                @media screen and (max-width: 767px) {
                    margin-block: 8px;
                }
            }

            h2 {
                color: $color-system-white;
            }

            h1 {
                color: $color-system-white;
            }

            ul {
                margin-top: 16px;

                @media screen and (max-width: 767px) {
                    margin-top: 8px;
                }

                li {
                    font-size: 1rem;
                    color: $color-system-white;
                    margin-bottom: 4px;
                }
            }
        }

        .rightPane {
            img {
                height: 275px;

                @media screen and (max-width: 767px) {
                    height: 80px;
                }
            }
        }
    }

    .heroBanner {
        height: 253px;

        img {
            height: 253px;
            width: 100%;
            object-fit: cover;
        }
    }

    h6 {
        margin-bottom: 20px;
    }

    p {
        margin-bottom: 30px;
    }
}

// THE GENDER INDEX PAGE CSS
.theGenderIndex {
    .date-accurate {
        font-size: 18px;
        line-height: 28px;
        font-weight: 600;
        letter-spacing: 0px;
        color: $color-system-heading;

        span {
            font-size: 24px;
            line-height: 35px;
            font-weight: 600;
            letter-spacing: 0px;
            color: $color-system-pink;
        }
    }

    .headWrap {
        margin-bottom: 32px;

        @media screen and (max-width: 1100px) {
            flex-direction: column;
            align-items: flex-start;
        }

        @media screen and (max-width: 767px) {
            .searchTitleCont {
                width: 100%;
                display: flex;
                justify-content: space-between;

                h2 {
                    font-size: 1.3rem;
                }

                .fa-share-nodes {
                    font-size: 32px;
                }
            }
        }

        .headerRightActions {
            @media screen and (max-width: 1100px) {
                display: flex;
                justify-content: space-between;
                width: 100%;
                margin-top: 8px;
            }

            .unStyledButton {
                padding: 10px 20px;
                border-radius: 6px;
                line-height: 1.75;

                &:hover {
                    background-color: #EDF0F1;
                }

                @media screen and (max-width: 767px) {
                    padding: 0px;
                }
            }

            .btn-blue {
                position: relative;

                .filterCount {
                    width: 32px;
                    height: 32px;
                    position: absolute;
                    right: -14px;
                    top: -14px;
                    background-color: $color-system-pink;
                    border: 1px solid $color-system-white;
                    border-radius: 50%;
                    font-size: 0.875rem;
                    color: $color-system-white;
                    text-align: center;
                    line-height: 30px;

                    @media screen and (max-width: 1100px) {
                        width: 24px;
                        height: 24px;
                        right: -10px;
                        top: -10px;
                        line-height: 22px;
                    }
                }

                @media screen and (max-width: 1100px) {
                    // background-color: transparent;
                    // color: $color-system-green;
                    padding-inline: 8px;
                    padding-block: 4px;
                }

                @media screen and (max-width: 767px) {
                    // width: 150px;
                    width: 100%;
                }
            }
        }
    }

    .textChartSummary {
        gap: 16px;
        margin-bottom: 24px;

        @media screen and (max-width: 1200px) {
            flex-wrap: wrap;
        }

        .chartDataTile {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 8px;
            flex: 1;
            // background-color: #EDF0F1;
            border-radius: 10px;
            padding: 11px 16px 11px 24px;
            min-height: 80px;

            @media screen and (max-width: 1200px) {
                width: 40%;
                flex: auto;
            }

            @media screen and (max-width: 767px) {
                width: 50%;
                flex: auto;
            }

            .chartDataContainer {
                display: flex;
                align-items: center;

                svg {
                    width: 25px;
                    height: 25px;
                    margin-right: toRem(32px);
                }
            }

            .chartDataIcon {
                margin-top: toRem(14px);
            }

            .infoWrap {
                .name {
                    font-size: 0.875rem;
                    line-height: 21px;
                    color: $color-system-heading;
                    // overflow: hidden;
                    // text-overflow: ellipsis;
                    // white-space: nowrap;
                    // max-width: 220px;
                }

                .percentCount {
                    font-size: toRem(30px);
                    line-height: toRem(37px);
                    color: $color-system-heading;
                    font-weight: $fontWeight600;
                }
            }

            .percentWrap {
                // min-width: 82px;
                background-color: $color-system-blue;
                margin-left: toRem(18px);
                padding: 6px;
                border-radius: 5px;
                font-size: toRem(14px);
                line-height: 17px;
                color: $color-system-white;
                font-weight: $fontWeight600;
                display: flex;
                justify-content: center;
                align-items: center;
                // width: 50px;
                height: 25px;
            }

            .chartDataStats {
                display: flex;
                align-items: center;
            }
        }

        .loader {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;

            img {
                width: 40px;
                height: auto;
            }

            span {
                margin-left: 8px;
            }
        }
    }

    .tableActionTab {
        display: flex;
        align-items: center;
        gap: 48px;
        margin-bottom: 24px;
        border-bottom: 1px solid #707070;

        @media screen and (max-width: 767px) {
            overflow: auto;
            gap: 8px;
            margin-bottom: 24px;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        @media screen and (min-width: 769px) and (max-width: 820px) {
            gap: 30px;
        }

        button {
            font-size: 1rem;
            line-height: 23px;
            color: $color-system-heading;
            min-width: 100px;
            padding-bottom: 8px;

            &.active {
                font-weight: $fontWeight600;
                border-bottom: 4px solid $color-system-green;

                @media screen and (max-width: 767px) {
                    border-bottom: 2px solid $color-system-green;
                }
            }

            @media screen and (max-width: 1100px) {
                // min-width: auto;
                font-size: 0.875rem;
            }

            @media screen and (max-width: 767px) {
                font-size: 0.8rem;
            }
        }
    }

    .tableChartWrapper {
        display: flex;
        gap: 16px;

        .commonNewLoader {
            margin-top: 0px !important;
            min-height: 490px;
            height: 100%;
        }

        @media screen and (max-width: 767px) {
            display: block;
        }

        .tableWrapper {
            flex: 1;
            min-height: 490px;
            height: 100%;

            @media screen and (max-width: 820px) {
                max-width: 100%;
                margin-bottom: 24px;
            }

            @media screen and (min-width: 1980px) {
                min-height: auto;
            }
        }

        .chartContainer {
            background: #EDF0F1 0% 0% no-repeat padding-box;
            border-radius: 10px;
            opacity: 1;
            padding: 16px 16px;
            flex: 1;
            min-height: 490px;
            height: 100%;

            @media screen and (max-width: 767px) {
                min-height: 300px;
            }

            @media screen and (min-width: 1980px) {
                min-height: 100%;
                height: auto;
            }
        }

        .donutChart {
            .apexcharts-legend {
                justify-content: center !important;

                .apexcharts-legend-marker {
                    border-radius: 3px !important;
                }

                .apexcharts-legend-text {
                    margin-left: 2px;
                }
            }

            .title {
                text-align: center;
                font-size: 18px;
                line-height: 27px;
                font-weight: 600;
                letter-spacing: 0px;
                color: #282D32;
                opacity: 1;
            }

            .chart {
                margin-top: 60px;
            }

            @media screen and (max-width: 767px) {
                .apexcharts-legend {
                    bottom: -16px;
                    flex-direction: column;
                }

                .chart {
                    margin-top: 16px;
                }
            }
        }

        .barChart {
            h3 {
                text-align: center;
                font-size: 1.125rem;
                line-height: 27px;
                letter-spacing: 0px;
                color: #282D32;
                opacity: 1;
            }

            .apexcharts-legend {
                justify-content: center !important;
                flex-direction: row-reverse;

                .apexcharts-legend-marker {
                    border-radius: 3px !important;
                }

                .apexcharts-legend-text {
                    margin-left: 4px;
                    margin-top: 2px;
                }
            }

            .apexcharts-tooltip {
                background: transparent;
            }

            .barChart-popover {
                border: 1px solid #012348;
                border-radius: 6px;
                opacity: 1;
                min-width: 200px;
                width: auto;

                &-head {
                    background: #282D32 0% 0% no-repeat padding-box;
                    border-radius: 6px 6px 0px 0px;
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 500;
                    letter-spacing: 0px;
                    color: #FFFFFF;
                    padding: 10px 16px;
                }

                &-body {
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    font-size: 12px;
                    line-height: 18px;
                    font-weight: 500;
                    letter-spacing: 0px;
                    color: #282D32;
                    padding: 10px 16px;
                    border-bottom-left-radius: 6px;
                    border-bottom-right-radius: 6px;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                }
            }

            @media screen and (max-width: 767px) {
                .apexcharts-text tspan {
                    font-size: 12px;
                }
            }
        }

        .investmentBarChart {
            .apexcharts-legend {
                gap: 12px;

                .apexcharts-legend-text {
                    margin-left: 8px;
                    padding: 0;
                }
            }
        }

        .generationBarChart {
            .apexcharts-legend {
                margin-top: 10px;
                gap: 8px;

                .apexcharts-legend-text {
                    // margin-left: 8px;
                    padding: 0;
                }
            }
        }
    }
}

.colourKey {
    margin-top: 16px;

    .tablekeyFooter {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;

        .key-text {
            font-size: 14px;
            color: #707070;
        }

        .keyIndicators {
            gap: 2px;

            .heatSpotCircle {
                width: 20px;
                height: 20px;
            }
        }

        @media screen and (max-width: 767px) {
            justify-content: space-between;

            .key-text {
                font-size: 12px;
                color: #707070;
                text-align: center;
            }

            .keyIndicators {
                .heatSpotCircle {
                    width: 12px;
                    height: 12px;
                }
            }
        }
    }
}

// .footerFilter {
//     display: none;
//     @media screen and (max-width: 1100px) {
//         margin-top: 24px;
//         margin-bottom: -26px;
//         display: block;
//     }
//     .btn-blue {
//         width: 100%;
//         padding: 6px 16px;
//         font-size: 0.75rem;
//         // background-color: transparent;
//         // color: $color-system-green;
//         // padding-inline: 0;
//         svg {
//             position: absolute;
//             left: 20px;
//             @media screen and (max-width: 767px) {
//                 position: static;
//             }
//         }
//         .filterCount {
//             display: block;
//             min-width: 24px;
//             height: 24px;
//             border-radius: 50%;
//             background: $color-system-red-primary;
//             border: 1px solid $color-system-white;
//             font-size: 0.875rem;
//             line-height: 22px;
//             color: $color-system-white;
//             text-align: center;
//             position: absolute;
//             right: 20px;
//         }
//     }
// }
.genderIndexMobileView {
    .groupBtn {
        display: flex;
        justify-content: space-between;
        border: 1px solid #707070;
        border-radius: 5px;

        .btn {
            width: 50%;
            border-radius: 0px;

            @media screen and (max-width: 767px) {
                padding: 5px 24px;
            }
        }

        .active {
            background-color: $color-system-green;
            color: $color-system-white;
        }
    }

    .tableChartWrapper {
        margin-top: 16px;
    }
}

.pure-material-button-outlined {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    border: solid 1px transparent;
    border-radius: 4px;
    vertical-align: middle;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    outline: none;
    cursor: pointer;
    padding-top: 1rem;
}

.pure-material-button-outlined::-moz-focus-inner {
    border: none;
}


/* Overlay */

.pure-material-button-outlined::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: currentColor;
    opacity: 0;
    transition: opacity 0.2s;
}


/* Ripple */

.pure-material-button-outlined::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 50%;
    padding: 50%;
    width: 32px;
    height: 36px;
    background-color: currentColor;
    opacity: 0;
    transform: translate(-50%, -50%) scale(1);
    transition: opacity 1s, transform 0.5s;
}


/* Hover, Focus */

.pure-material-button-outlined:hover::before {
    opacity: 0.04;
    background-color: transparent;
}

.pure-material-button-outlined:focus::before {
    opacity: 0.12;
    background-color: transparent;
}

.pure-material-button-outlined:hover:focus::before {
    opacity: 0.16;
    background-color: transparent;
}


/* Active */

.pure-material-button-outlined:active::after {
    opacity: 0.16;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0s;
}


/* Disabled */

.pure-material-button-outlined:disabled {
    color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
    background-color: transparent;
    cursor: initial;
}

.pure-material-button-outlined:disabled::before {
    opacity: 0;
}

.pure-material-button-outlined:disabled::after {
    opacity: 0;
}

.subContent-tina p {
    font-size: 1.2rem;
    line-height: 1.4;
}

.view-report-btn:hover button {
    color: #fff;
    background-color: #05274B;
}

.landingPage+.singleLayout .supportersLogos li.aws-icon a img {
    height: 60px;
    object-fit: contain;
}

.landingPage+.singleLayout .supportersLogos li.goldman-icon a img {
    height: 60px;
    object-fit: contain;
}

.landingPage+.singleLayout .supportersLogos li.mnai-icon a img {
    height: 80px;
    margin-top: -24px;
    object-fit: contain;
}

.landingPage+.singleLayout .supportersLogos li.smith-icon a img {
    height: 30px;
    object-fit: contain;
}

.landingPage+.singleLayout .supportersLogos li.svb-icon a img {
    height: 30px;
    object-fit: contain;
}

.landingPage+.singleLayout .supportersLogos li.hsbc-icon a img {
    height: 30px;
    object-fit: cover;
}


input.error {
    border: 1px solid red;
}

textarea.error {
    border: 1px solid red;
}

.greetingMessage {
    background: $color-system-green;
    z-index: 10000;
    position: relative;
    padding: 22px 8px;
    margin-bottom: 16px;

    p {
        color: #fff;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
    }

    .fa-xmark {
        position: absolute;
        right: 24px;
        top: 24px;
        font-size: 22px;
        color: #fff;
        cursor: pointer;

    }

}

.pageNotFoundWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #F4F7F8;
    border-radius: 10px;

    .pageNotFound {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-inline: 25%;
        gap: 40px;

        @media screen and (max-width: 1400px) {
            padding-inline: 15%;
        }

        @media screen and (max-width: 767px) {
            padding-inline: 20px;
            flex-direction: column-reverse;
        }

        .leftWrap {
            flex: 1;

            @media screen and (max-width: 767px) {
                text-align: center;
            }

            h1 {
                font-size: 100px;
                line-height: 60px;
                font-weight: bold;
                color: $color-system-pink;

                @media screen and (max-width: 1400px) {
                    font-size: 70px;
                    line-height: 40px;
                }

                @media screen and (max-width: 767px) {
                    font-size: 36px;
                    line-height: 40px;
                }
            }

            h3 {
                font-size: 40px;
                line-height: 60px;
                font-weight: bold;
                margin-block: 40px 70px;

                @media screen and (max-width: 1400px) {
                    font-size: 30px;
                    line-height: 40px;
                    margin-block: 20px 35px;
                }

                @media screen and (max-width: 767px) {
                    font-size: 20px;
                    line-height: 30px;
                    font-weight: bold;
                    margin-block: 16px;
                }
            }

            p {
                color: $color-system-heading;
            }

            .action {
                display: inline-block;
                margin-top: 70px;

                @media screen and (max-width: 1400px) {
                    margin-top: 35px;
                }
            }
        }

        .rightWrap {
            flex: 1;
            text-align: center;

            img {
                height: auto;
                max-width: 100%;

                @media screen and (max-width: 1400px) {
                    max-width: 80%;
                }

                @media screen and (max-width: 1100px) {
                    max-width: 70%;
                }

                @media screen and (max-width: 767px) {
                    max-width: 120px;
                }
            }
        }
    }
}