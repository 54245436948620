//MAIN COLORS HERE
$color-system-black: #000;
$color-system-white: #fff;
$color-system-red-primary: #FD7278;
$color-system-pink: #FD7278;
$color-system-validation-error: #e94545;
$color-system-blue: #012348;
$color-system-green: #00A7B7;
$color-system-green-dark: #012348;
$color-system-heading: #282D32;
$color-system-grey: #DBE0E5;
$color-system-light-grey: #70707080;
$color-system-green-solid: #282D32;

//FONT WEIGHT HERE
$fontWeight100: 100;
$fontWeight200: 200;
$fontWeight300: 300;
$fontWeight400: 400;
$fontWeight500: 500;
$fontWeight600: 600;
$fontWeight700: 700;
$fontWeight800: 800;
$fontWeight900: 900;

$breakpoints: (
    xs: 576px,
    sm: 768px,
    md: 1000px,
    lg: 1300px,
    xl: 1500px,
    xxl:1800px
);

$base-font-size: 16px;