.tableWrapper {
    table {
        border-collapse: separate;
        border-spacing: 0 0.5rem;
        width: 99%;

        thead {
            tr {
                th {
                    font-size: 0.75rem;
                    color: $color-system-heading;
                    font-weight: $fontWeight600;
                    text-align: left;
                }
            }
        }

        th {
            position: -webkit-sticky; // this is for all Safari (Desktop & iOS), not for Chrome
            position: sticky;
            top: 0;
            z-index: 1; // any positive value, layer order is global
            background: #fff; // any bg-color to overlap
        }

        tbody {

            tr {
                background-color: #EDF0F1;

                td {
                    border-radius: 4px;
                    padding: 12px 0;
                    //padding-left: 16px;
                    font-size: 0.875rem;
                    color: $color-system-heading;
                }
            }
        }


    }

    .mainTable {
        max-height: 490px;
        overflow: auto;
        @media screen and (min-width: 1980px) {
            max-height: 100%;
        }

        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 10px;
            height: 10px;
        }

        &::-webkit-scrollbar-track {
            background-color: aliceblue;
        }

        tr {
            position: relative;

            th:first-child {
                padding-left: 16px;
            }

            td:first-child {
                width: 48%;
                padding-left: 16px;
                font-weight: $fontWeight600;

                * {
                    font-weight: $fontWeight600;
                }
            }

            th {
                cursor: pointer;
                line-height: 18px;
                font-weight: 800;
                padding-block: 8px;
                vertical-align: bottom;
            }

            .sortIcon {
                position: absolute;
                margin-top: 2px;
                font-size: 12px;
                margin-left: 8px;
            }
        }

        .tableHeatSpotItemCont {

            display: flex;
            align-items: center;
            gap: 20px;

            .tableHeatSpotItem {
                width: 24px;
                height: 24px;
                border-radius: 4px;

                @media screen and (max-width: 767px) {
                    width: 18px;
                    height: 18px;
                }
            }

            @media screen and (max-width: 767px) {
                gap: 16px;
            }
        }
    }
}