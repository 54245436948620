.posts-container {
    font-weight: 300;
    font-size: 16px;
    line-height: 1.5;
    color: #2a2b2c;
    margin-top: 24px;
    margin-bottom: 24px;
    max-width: 100%;
    border: 1px solid #e5e5e5;
    box-shadow: 8px 8px 16px rgba(51, 51, 51, 0.1);
    border-radius: 5px;
    padding: 24px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.posts-container.article-container h3 {
    text-align: left;
    margin: 0;
    padding-bottom: 1rem;
}

.posts-container-link {
    text-decoration: none;
    width: 100%;
}

.posts-container:hover {
    filter: brightness(90%);
}

/* Heading styles */

.main-heading {
    font-size: 24px;
    margin-bottom: 8px;
    max-height: 72px;
    overflow: hidden;
    text-align: left;
    font-weight: 700;
}

/* Category section styles */

.category-section {
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 24px;
    text-align: left;
}

/* Image section styles */

.image-section {
    width: 100%;
    height: auto;
    margin-bottom: 16px;
    object-fit: contain;
}

/* Description section styles */

.description-section {
    font-size: 16px;
    font-weight: normal;
    margin-top: 16px;
    text-align: left;
}

/* Responsive styles */

@media (max-width: 767px) {
    .posts-container {
        max-width: 100%;
    }
}