#interactive-map {
    display: flex;
    justify-content: center;
    // height: 520px;
    position: relative;

    .expand-con {
        position: absolute;
        right: 0px;
        cursor: pointer;
        border: 1px solid #BFC6C8;
        border-radius: 5px;
        opacity: 1;
        // padding: 8px;
        width: 38px;
        height: 38px;
        display: flex;
        justify-content: center;
        align-items: center;

        .fa-up-right-and-down-left-from-center {
            font-size: 16px;
        }
    }

    .LocalEnterprisePartnership {
        margin-top: -100px;

        @media screen and (max-width: 767px) {
            margin-top: 0;
        }
    }
}

#interactive-map .Map>.map-container-col {
    height: 100%;
}

#interactive-map .Map>.map-container-col div.MapDiagram {
    width: 100%;
    height: 100%;
}

#interactive-map .Map>.map-container-col div.MapDiagram .map-svg {
    width: 100%;
    height: 100%;
}

#interactive-map .Map>.map-container-col div.MapDiagram>div .map-svg g[data-code].in-active {
    opacity: 0.65;
}

#interactive-map .Map>.map-container-col div.MapDiagram>div .map-svg g[data-code].active {
    opacity: 1;
}

#interactive-map .Map>.map-container-col div.MapDiagram>div .map-svg path[data-code].in-active {
    opacity: 0.65 !important;
}

#interactive-map .Map>.map-container-col div.MapDiagram>div .map-svg path[data-code].active {
    opacity: 1 !important;
}

#interactive-map .Map>.map-container-col div.MapDiagram>div .map-svg polygon[data-code].in-active {
    opacity: 0.65 !important;
}

#interactive-map .Map>.map-container-col div.MapDiagram>div .map-svg polygon[data-code].active {
    opacity: 1 !important;
}

/*#interactive-map .Map > .map-container-col div.MapDiagram > div svg g[data-code]:not([style=""]) path{
      stroke: white;
  }
  
  #interactive-map .Map > .map-container-col div.MapDiagram > div svg g[data-code][style=""] {
      fill: transparent;
  }
  
  #interactive-map .Map > .map-container-col div.MapDiagram > div svg g[data-code][style=""] path{
      stroke: black;
  }*/



#interactive-map .Map>.map-container-col div.MapDiagram>div .map-svg path[data-code],
#interactive-map .Map>.map-container-col div.MapDiagram>div .map-svg polygon[data-code],
#interactive-map .Map>.map-container-col div.MapDiagram>div .map-svg g[data-code] {
    cursor: pointer;
    fill: #ddd;
}

#interactive-map .Map>.map-container-col div.MapDiagram>div .map-svg g[data-code="unknown"] {
    display: none !important;
}


.MapDiagram {
    .map-svg {
        cursor: grab;
        touch-action: none
    }

    .zoom-btn-group {
        position: absolute;
        right: 60px;
        display: flex;
        flex-direction: column;
        font-size: 0;
        line-height: 1;
        white-space: nowrap;


        button {
            font-size: 14px;
            letter-spacing: .1em;
            text-decoration: none;
            background: transparent;
            border: 1px solid #BFC6C8;
            display: inline-block;
            padding: 4px 8px;
            outline: 0; // no glow crap
            cursor: pointer;
            background: #fff;

            svg {
                font-size: 14px;
            }

            &:first-child {
                // border-left-width: 2px;
                border-radius: 6px 6px 0 0;
            }

            &:last-child {
                border-radius: 0 0 6px 6px;
            }

            &:link {
                color: #bdc3c7;
            }

            &:visted {
                color: #bdc3c7;
            }

            &:hover {
                background: #012348;
                color: #fff;
            }

            &:active {
                background: #fff;
                color: #012348;
            }
        }
    }
}