.pagination {
    margin: 2px 0;

    ul {
        list-style: none;
        display: flex;
        justify-content: right;
        gap: 2px;
        cursor: pointer;

        li {
            a {
                display: inline-block;
                padding: 2px 12px;                
                font-size: 1.125rem;
                line-height: 27px;
                color: #777;
                text-transform: none;
            }

            &.prev,
            &.next {
                a {
                    font-size: x-large;
                }

                &:hover {
                    a {}
                }

                &.disabled {
                    a {
                        color: #777;
                    }
                }
            }

            &:hover,

            &.selected {
                a {
                    background-color: $color-system-green;
                    color: $color-system-white;
                    border-radius: 4px;
                    text-align: center;
                }
            }

        }
    }

    .disableBtn {
        color: gray;
    }


}