// COMMON CONTACT FORM
.contactForm {
    padding: 80px 0;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    h4 {
        font-size: 1.5rem;
        font-weight: $fontWeight600;
        padding-bottom: 8px;
        border-bottom: 3px solid #012348;
        margin-bottom: 33px;
    }

    form {
        width: 100%;
        margin: 52px auto;
        text-align: center;

        .textField,
        .textarea {
            max-width: 650px;
            margin: 1.25rem auto;
        }

        .action {
            width: 100%;
            max-width: 650px;
            margin: 0 auto;
            text-align: right;
        }
    }
}

// CSS FOR FOOTER PAGES
.supporterPage {
    h3 {
        text-align: center;

        &+p {
            text-align: center;
            margin: 58px 0 62px 0;

            @media screen and (max-width: 767px) {
                margin: 1.5rem 0 32px 0;
            }
        }
    }

    .supportersLogos {
        display: grid;
        grid-gap: 8px;
        grid-template-columns: repeat(auto-fill, minmax(252px, 1fr));

        li {
            // background-color: rgba(0, 167, 183, 0.09);
            height: 116px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
            }

            img {
                height: 40px;
                object-fit: contain;
            }
        }
    }
}

.teamPage {
    h3 {
        text-align: center;
        margin-bottom: 40px;

        @media screen and (max-width: 767px) {
            margin-bottom: 1.5rem;
        }

        &+p {
            text-align: center;
            margin: 58px 0 62px 0;
        }
    }

    .headofTeam {
        max-width: 740px;
        margin: 0 auto;

        .contactTles {
            margin-top: 64px;
        }
    }

    .listofTeam {
        display: flex;
        gap: 78px;
        flex-wrap: wrap;
        margin: 132px 0 54px 0;

        @media screen and (max-width: 768px) {
            flex-direction: column;
        }

        li {
            width: calc(50% - 39px);

            @media screen and (max-width: 768px) {
                width: 100%;
            }
        }
    }

    .contactTles {
        text-align: center;

        figure {
            display: block;
            width: 200px;
            height: 200px;
            border: 1px solid #707070;
            border-radius: 50%;
            margin: 0 auto 2.375rem auto;
            border-radius: 50%;

            img {
                width: 100%;
                height: 100%;
                max-width: 100%;
                border-radius: 50%;
                object-fit: contain;
            }
        }

        .title {
            font-size: 1.125rem;
            color: #282D32;
            font-weight: $fontWeight600;
            margin-bottom: 8px;
        }

        .role {
            font-size: 1.125rem;
            color: #707070;
            margin-bottom: 2.375rem;
        }

        .body-default-copy-large {
            text-align: left;
            margin-top: 16px;

            &:first-child {
                margin-top: 0;
            }
        }
    }
}

.contactPage {

    h3 {
        margin-bottom: 42px;
        text-align: center;

        @media screen and (max-width: 767px) {
            margin-bottom: 1.5rem;
        }
    }

    .contactText {
        max-width: 648px;
        margin: 42px auto;
    }

    .address {
        margin: 1.875rem 0 76px 0;
    }

    form {
        max-width: 650px;
        margin: 42px auto;

        .textField {
            flex: 1;
            margin-bottom: 16px;

            input {
                text-align: left;
            }
        }

        .textarea {
            textarea {
                height: 209px;
                text-align: left;
            }
        }

        .action {
            margin-top: 16px;

            button {
                min-width: 180px;
                width: 100%;
            }
        }
    }

    .messageReceivedBox {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #C3C3C3;
        border-radius: 6px;
        padding-block: 116px;
        max-width: 648px;
        margin: 0 auto;

        @media screen and (max-width: 767px) {
            padding-block: 20px;
        }

        .content {
            padding-inline: 80px;

            @media screen and (max-width: 767px) {
                padding-inline: 20px;
            }

            h5 {
                display: flex;
                align-items: center;
                margin-bottom: 44px;

                @media screen and (max-width: 767px) {
                    margin-bottom: 24px;
                }

                svg {
                    font-size: 1.5rem;
                    color: $color-system-pink;
                    margin-right: 1.5rem;
                }
            }
        }
    }
}

.privacyPage {
    h3 {
        margin: 3.438rem 0 2.375rem;
        text-align: center;
        font-size: 1.875rem;
        color: #282D32;

        @media screen and (max-width: 767px) {
            text-align: left;
            margin: 1.5rem 0;
        }
    }

    h5 {
        font-size: 1.125rem;
    }

    h6 {
        margin: 1.875rem 0;
    }

    p {
        margin-bottom: 1.875rem;
    }
}

.faq-accordion .accordion__item {
    .accordion__heading .accordion__button {
        font-size: 1rem;
        line-height: 1.563rem;
        padding: 1rem 1.5rem;
    }
}

.faq-accordion {
    .accordion__item {
        .accordion__heading {
            .accordion__button {
                .accordion__state {

                    .fa-plus,
                    .fa-minus {
                        font-size: 1.25rem;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 767px) {
        .accordion__item {
            .accordion__heading {
                .accordion__button {
                    .accordion_title {
                        max-width: 264px;
                    }
                }
            }
        }
    }
}

.custom-accordion {
    .accordion__item {
        margin-bottom: 0.813rem;
    }
}

.custom-accordion .accordion__item:last-of-type {
    margin: 0;
}