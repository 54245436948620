body {
    margin: 0;
}

.welcomeWrapper {
    position: relative;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    background-color: #E9E9E9;    
    overflow: hidden;



    .eclipseLeft {
        position: fixed;
        bottom: -25px;
        left: -90px;
        width: 360px;
        height: 350px;
        overflow: hidden;

        img {
            max-width: 100%;
        }

        @media screen and (max-width:1460px) {
            width: 260px;
            height: 260px;
        }

        @media screen and (max-width:1300px) {
            width: 220px;
            height: 220px;
        }

        @media screen and (max-width:767px) {
            width: 160px;
            height: 160px;
            bottom: -34px;
            left: -34px;
        }
    }

    .eclipseRight {
        position: fixed;
        top: -130px;
        right: -130px;
        width: 360px;
        height: 350px;
        overflow: hidden;

        img {
            max-width: 100%;
        }

        @media screen and (max-width:1460px) {
            width: 260px;
            height: 323px;
            top: -60px;
            right: -60px;
        }

        @media screen and (max-width:1300px) {
            width: 220px;
            height: 220px;
        }

        @media screen and (max-width:767px) {
            width: 160px;
            height: 160px;
            top: -60px;
            right: -60px;
        }
    }

    .logo {
        margin-left: 56px;
        margin-top: 50px;

        @media screen and (max-width: 767px) {
            margin-bottom: 49px;
            margin-top: 111px;
            margin-left: auto;
            text-align: center;
        }

        a {
            display: block;
        }

        img {
            height: 59px;

            @media screen and (max-width: 1100px) {
                height: 43px;
            }
        }
    }

    .innerContainer {
        max-width: 1074px;
        margin: 0 auto;
    }

    h1 {
        font-size: 60px;
        line-height: 90px;
        color: #012348;
        text-align: center;
        margin: 0;
        font-weight: normal;

        @media screen and (max-width: 767px) {
            font-size: 26px;
            line-height: 39px;
        }
    }

    h2 {
        font-size: 3.75rem;
        line-height: 90px;
        color: #FD7278;
        font-weight: 600;
        text-align: center;
        margin: 0;

        @media screen and (max-width: 767px) {
            font-size: 1.3rem;
            line-height: 39px;
        }
    }

    .infoText {
        margin-top: 80px;
        padding: 0 25%;
        line-height: 27px;
        text-align: center;
        margin-bottom: 0;

        @media screen and (max-width: 767px) {
            margin-top: 30px;
            padding: 0 24px;
            font-size: 12px;
            line-height: 20px;
        }
    }

    .daysRemaining {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 91px 0 111px 0;

        @media screen and (max-width: 767px) {
            padding: 34px 0;
        }

        .dataBox {

            .dataCount {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 113px;
                height: 113px;
                background: #00A7B7;
                border-radius: 6px;
                font-size: 40px;
                color: #fff;
                margin-bottom: 17px;

                @media screen and (max-width: 1100px) {
                    width: 94px;
                    height: 94px;
                }

                @media screen and (max-width: 767px) {
                    width: 48px;
                    height: 48px;
                    font-size: 20px;
                    margin-bottom: 8px;
                }
            }

            .dataTitle {
                font-size: 18px;
                line-height: 27px;
                color: #282D32;
                text-align: center;
                font-weight: 600;

                @media screen and (max-width: 767px) {
                    font-size: 8px;
                    line-height: 12px;
                }
            }
        }
    }

    .footerRight {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            font-size: 1rem;
            line-height: 25px;
            color: #515151;

            @media screen and (max-width: 767px) {
                font-size: 12px;
                line-height: 17px;
            }
        }

        img {
            height: 41px;
        }
    }
}