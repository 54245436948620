.weightChart {

    margin-top: 8px;

    h3 {
        text-align: center;
        font-size: 1.125rem;
        line-height: 27px;
        letter-spacing: 0px;
        color: #282D32;
        opacity: 1;
    }

    .weightChart-main {
        display: flex;
        justify-content: center;

        @media screen and (max-width: 767px) {
            flex-direction: column;
            align-items: center;
        }
    }

    .levelCont {
        margin-top: 24px;
    }

    .level1 {
        gap: 8px;
        // justify-content: space-evenly;

        @media screen and (max-width: 767px) {
            gap: 3px;
        }
    }

    .level2 {
        margin: 8px;

        @media screen and (max-width: 767px) {
            margin: 6px 2px;
        }
    }

    .info {
        // margin-top: 32px;
        color: #282D32;
        font-size: 18px;
        line-height: 18px;
        font-weight: 500;
        text-align: left;

        .count {
            font-size: 14px;
            color: #FD7278;
            font-weight: bold;
        }
    }

    .fa-user {
        font-size: 24px;
    }

    .faSterlingSignCont {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #012348;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 767px) {
            width: 18px;
            height: 18px;
        }

        .fa-sterling-sign {
            font-size: 12px;
        }
    }

    .faVenusCont {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 767px) {
            width: 18px;
            height: 18px;
        }

        .fa-venus {
            font-size: 18px;
        }
    }

    .legend {
        margin-left: 64px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-top: auto;
        margin-bottom: auto;


        .legendItem {
            align-items: center;
            gap: 8px;

            .faSterlingSignCont {
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background-color: #012348;
                display: flex;
                justify-content: center;
                align-items: center;

                .fa-sterling-sign {
                    font-size: 10px;
                }
            }

            .fa-venus {
                font-size: 16px;
            }
        }

        svg {
            width: 20px;
        }

        .weight-legend-label {
            color: rgb(55, 61, 63);
            font-size: 18px;
            font-weight: 400;
            margin-left: 10px;
            font-family: 'Poppins', sans-serif;

            @media screen and (max-width: 767px) {
                font-size: 14px;
            }
        }

        @media screen and (max-width: 767px) {
            margin-left: 0;
            margin-top: 16px;
            gap: 8px;
        }
    }
}