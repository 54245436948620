@import "../base/variables.scss";
@import "../mixins/breakpoint";
@import "../base/utilities";
.app-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 32px;
    padding-block: 2rem;
    position: sticky;
    top: 0;
    z-index: 9;
    background-color: $color-system-white;
    width: 100%;
    @include media-breakpoint-down("md") {
        padding-inline: 16px;   
    }

    
    .logo {
        height: 59px;

        @include media-breakpoint-down("md") {
            height: 43px;
        }

        img {
            height: 59px;
            object-fit: contain;

            @include media-breakpoint-down("md") {
                // height: 43px;
            }
        }
    }

    .headerTabsNavigation {
        ul {
            display: flex;
            align-items: center;
            gap: 66px;

            @include media-breakpoint-down("xl") {
                gap: 36px;
            }

            @include media-breakpoint-down("md") {
                flex-direction: column;
                gap: 44px;
            }

            li {
                padding: 0 2px;

                a {
                    display: inline-block;
                    font-size: 0.875rem;
                    line-height: 21px;
                    color: $color-system-black;
                    padding-block: 4px;
                }

                &:hover {
                    border-bottom: 2px solid $color-system-blue;
                }

                &.active {
                    border-bottom: 2px solid $color-system-red-primary;

                    a {
                        font-weight: $fontWeight600;
                    }
                }
            }
        }

        @include media-breakpoint-down("md") {
            display: none;
        }

        &.active {
            display: block;
            width: 100%;
            height: calc(100% - 94px);
            position: fixed;
            left: 0;
            top: 94px;
            background: $color-system-white;
            overflow-y: scroll;

            @media screen and (max-width: 768px) {
                top: 94px;
                height: calc(100% - 94px);
            }
        }
    }

    .rightNavigationWrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 70px;

        @include media-breakpoint-down("xl") {
            gap: 36px;
        }

        .exploreBtnWrap {
            .exploreBtn {
                width: 124px;

                @include media-breakpoint-down("md") {
                    display: none;
                }
            }
        }
    }

    .menuBtn {
        display: none;
        background-color: transparent;
        border: none;
        cursor: pointer;

        @include media-breakpoint-down("md") {
            display: flex;
            align-items: center;
        }

        svg {
            font-size: 1.7rem;
            color: $color-system-black;
        }
    }
}

.scrolled {
    header {
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 10%);
        transition: all .3s ease;
    }
}

.app-header .headerTabsNavigation ul li:hover {
    border-bottom: none;
}

.app-header .headerTabsNavigation ul li:hover::after {
    width: 100%;
    //transition: width .3s;
}

.app-header .headerTabsNavigation ul li::after {
    content: '';
    display: block;
    width: 0;
    height: 4px;
    background: #012348;
    transition: width .3s;
}

.app-header .headerTabsNavigation ul li.active {
    border-bottom: none;
}

.app-header .headerTabsNavigation ul li.active::after {
    content: '';
    display: block;
    width: 100%;
    height: 4px;
    background: #FD7278;
}

.app-header .headerTabsNavigation ul li.active:hover::after {
    content: '';
    display: block;
    width: 100%;
    height: 4px;
    background: #012348;
    transition: width .5s;
}
.bgGradient{
    background: transparent linear-gradient(to right, #FFFFFF 0%, #FFFFFF 50%, #00A7B7 100%) 0% 0% no-repeat padding-box;
    @media screen and (max-width: 1100px) {
        background: $color-system-white;
    }
}