.commonDrawer {
    width: 460px;
    overflow: hidden;
    background-color: #fff;

    @media screen and (max-width: 767px) {
        width: 100%;
        height: 100%;
    }

    &.large {
        width: 746px;
    }

    .commonDrawerHeader {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 30px;

        .backButton {

            svg {
                font-size: 1.5rem;
                color: $color-system-heading;
            }
        }

        .commonDrawerTitle {
            font-size: 1.125rem;
            line-height: 25px;
            color: $color-system-heading;
            font-weight: $fontWeight600;

        }

        .closeButton {
            svg {
                font-size: 1.5rem;
                color: $color-system-heading;
            }
        }
    }

    .commonDrawerContent {
        padding: 0 30px;
        overflow: auto;
        height: calc(100vh - 156px);

        // @media screen and (max-width: 767px) {
        //     height: calc(100% - 320px);
        // }
    }

    .commonDrawerFooter {
        display: flex;
        justify-content: space-between;
        background-color: $color-system-white;
        padding: 20px 30px;

        .btn-blue {
            width: 250px;
        }

        @media screen and (max-width: 767px) {
            position: fixed;
            bottom: 0;
            right: 0;
            left: 0;

            .btn-blue {
                width: 180px;
            }

            .btn-red-outlined {
                width: 60px;
            }
        }
    }

    &.filterDrawer {
        .filter-tooltip {
            .fa-circle-info {
                font-size: 20px;
            }

            .filters-tooltip {
                width: 360px;
                text-align: center;
            }

            @media screen and (max-width: 767px) {
                .filters-tooltip {
                    width: 100%;
                }
            }
        }

        .filterRowWrap {
            margin-bottom: 24px;


            .filterListHeader {
                border-top: 1px solid #E1E1E1;
                border-bottom: 1px solid #E1E1E1;
                margin-bottom: 24px;
                padding: 14px 30px;

                h6 {
                    font-size: 1.125rem;
                    line-height: 18px;
                    color: $color-system-heading;
                    font-weight: $fontWeight600;
                    margin-bottom: 2px;
                }

                .subHeading {
                    font-size: 0.75rem;
                    line-height: 18px;
                    color: $color-system-heading;
                }
            }

            .filterListBody {
                padding: 0 30px;
            }

            .filterListItem {
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }

                .title {
                    font-size: 1rem;
                    color: $color-system-blue;
                    font-weight: $fontWeight400;
                }

                .action {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 24px;

                    .selectedValue {
                        font-size: 0.875rem;

                        &.greenText {
                            text-align: right;
                            color: $color-system-green;
                        }
                    }

                    svg {
                        font-size: 1.2rem;
                    }
                }
            }

        }

        .filterInnerWrap {
            padding: 0 30px;

            .subHead {
                font-size: 0.875rem;
            }

            .searchWrap {
                position: relative;
                margin-bottom: 48px;

                svg {
                    position: absolute;
                    left: 16px;
                    top: 14px;
                    color: rgba(112, 112, 112, 0.4);
                }

                input {
                    width: 100%;
                    border: 1px solid rgba(112, 112, 112, 0.46);
                    border-radius: 4px;
                    padding-block: 10px;
                    padding-left: 60px;
                    font-size: 0.875rem;
                    color: #707070;
                }
            }
        }
    }


}

.EZDrawer {
    box-shadow: -3px 3px 6px #00000029;

    .EZDrawer__container {
        @media screen and (max-width: 767px) {
            width: 100% !important;
            height: 100% !important;
        }
    }
}

// Modal Styles

.commonModalWrapper {
    // display: none;
    // position: fixed;
    // left: 0;
    // top: 0;
    // right: 0;
    // bottom: 0;
    // z-index: 99999;
    // background-color: rgba(0, 0, 0, 0.15);

    // &.show {
    //     display: block;
    // }

    .commonModal {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 480px;
        background-color: $color-system-white;
        box-shadow: 0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.14);
        border-radius: 10px;
        outline: 0;
        overflow: auto;

        @media screen and (max-width: 480px) {
            width: 96%;
        }

        .modalHeader {
            position: relative;
            display: flex;
            justify-content: space-between;
            padding: 30px 20px 30px 32px;
            border-bottom: 1px solid rgba(112, 112, 112, 0.4);

            h5 {
                display: block;
                font-size: 1.125rem;
                line-height: 27px;
                font-weight: $fontWeight600;
            }

            .headerClose {
                height: 21px;
                border: none;
                background-color: transparent;
                cursor: pointer;

                svg {
                    font-size: 1.4rem;
                    color: $color-system-black;
                }
            }
        }

        .modalBody {
            padding: 24px 32px;
            min-height: 15vh;
            max-height: 70vh;
            // overflow-y: auto;

            p {
                color: $color-system-heading;
            }
        }

        .commonModal_footer {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            padding: 30px 20px 30px 32px;
        }
    }

    .confirm {
        .commonModal_header {
            margin-bottom: 0;

            button {
                svg {
                    color: $color-system-black;
                }
            }
        }

        .commonModal_body {
            min-height: auto;
        }

        .commonConfirmBox {
            text-align: center;

            h2 {
                margin-block: 16px;
            }

        }
    }

    .shareModal {
        .shareViaWrap {
            display: flex;
            justify-content: space-between;
            margin: 24px 0;

            .socialLinks {
                a {
                    display: block;
                    text-align: center;

                    svg {
                        font-size: 3rem;
                        color: $color-system-green;
                    }

                    .iconName {
                        display: block;
                        font-size: 10px;
                        line-height: 16px;
                        color: #282D32;
                    }
                }
            }
        }

        .linkCopyWrap {
            display: flex;
            gap: 4px;
            margin-top: 24px;

            .textField {
                flex: 1;
                margin-bottom: 0;

                input {
                    padding: 8.5px 20px;
                }
            }

            button {
                padding: 4px 24px;
            }
        }

        .modalBody {
            min-height: 286px;
            // overflow: hidden;
        }
    }

    .cookiesModal {
        width: 100%;
        max-width: 746px;

        @media screen and (max-width: 767px) {
            width: 96%;
        }

        .modalHeader {
            justify-content: flex-end;
            border-bottom: none;
            padding-bottom: 16px;
        }

        .modalBody {
            max-height: 90vh;
        }

        h5 {
            text-align: center;
            font-weight: $fontWeight700;
        }

        p {
            font-size: 0.875rem;
            line-height: 22px;
            color: #707070 !important;
        }

        .acceptAllBtn,
        .confirmBtn {
            display: block;
            width: 100%;
            margin: 24px auto;
        }

        .cookiesControls {
            margin: 32px 0;
            padding: 32px 0;
            border-top: 3px dashed $color-system-grey;
            border-bottom: 3px dashed $color-system-grey;

            .controlList {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 80px;

                @media screen and (max-width: 767px) {
                    gap: 16px;
                }

                &+.controlList {
                    margin-top: 32px;
                }

                .leftPane {
                    display: flex;
                    gap: 32px;
                    flex: 1;

                    svg {
                        position: relative;
                        top: 4px;
                        font-size: 0.75rem;
                        color: $color-system-heading;
                    }

                    h6 {
                        margin-bottom: 8px;
                    }
                }
            }
        }
    }

    .confirmFilter {
        width: 386px;

        @media screen and (max-width: 480px) {
            width: 96%;
        }

        .modalBody {
            padding: 0;
            padding-top: 36px;
            min-height: 100%;

            h5 {
                text-align: center;
                font-size: 18px;
                line-height: 27px;
                font-weight: 600;
                letter-spacing: 0px;
                color: #282D32;
                opacity: 1;
            }

            p {
                margin-top: 24px;
                margin-bottom: 24px;
                text-align: center;
                font-size: 16px;
                line-height: 25px;
                font-weight: 500;
                letter-spacing: 0px;
                color: #282D32;
            }

            .confirmModalButtons {
                margin-top: 24px;
                display: flex;
                justify-content: space-between;
                height: 56px;
                border-top: 1px solid #707070;
                overflow: hidden;

                button {
                    text-align: center;
                    width: 50%;
                    border: none;
                    cursor: pointer;
                    opacity: 1;

                    &:hover {
                        opacity: 0.9;
                    }
                }

                .no {
                    font-size: 1rem;
                    line-height: 25px;
                    font-weight: 500;
                    letter-spacing: 0px;
                    color: #282D32;
                    background: #FFFFFF;
                    border-radius: 0px 0px 0px 10px;

                    &:hover,
                    &:focus {
                        background-color: $color-system-grey;
                        border: 1px solid $color-system-grey;
                    }
                }

                .yes {
                    background: #012348;
                    border-radius: 0px 0px 10px 0px;
                    font-size: 1rem;
                    line-height: 25px;
                    font-weight: 500;
                    letter-spacing: 0px;
                    color: #FFFFFF;
                }
            }
        }
    }

    .welcomeModal {
        width: 800px;

        @include media-breakpoint-down("md") {
            width: 500px;
        }

        @include media-breakpoint-down("sm") {
            width: 500px;
            max-height: 90vh;
        }

        @include media-breakpoint-down("xs") {
            width: 90%;
        }


        .modalHeader {
            align-items: center;

            h5 {
                font-size: 24px !important;
            }

            .fa-xmark {
                font-size: 26px;
                color: $color-system-red-primary;
            }
        }

        p {
            text-align: left;
            font-size: 16px;
            line-height: 20px;
            font-weight: 600;
            padding-top: 16px;
            padding-bottom: 16px;
        }

        ul {
            list-style-type: none;
            padding: 0;
        }

        ul li {
            display: flex;
            align-items: flex-start;
            padding-top: 16px;
            padding-bottom: 16px;

            b {
                font-weight: 600;
            }
        }

        ul li::before {
            color: transparent;
            content: " ";
            margin-right: 15px;
            padding: 8px;
            margin-top: 2px;
            background-color: #00a7b7;
            border-radius: 50%;
        }

        button {
            margin-top: 18px;
            width: 80%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 10%;
        }

        .modalBody {
            @include media-breakpoint-down("sm") {
                max-height: none;
            }
        }
    }
}

.cookiesBarModal {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $color-system-white;
    // border-top: 1px solid #707070;
    padding: 44px 40px;
    z-index: 999;

    @media screen and (max-width: 767px) {
        padding: 40px 30px;
    }

    .content {
        display: flex;
        justify-content: space-between;
        gap: 60px;
        align-items: center;

        @media screen and (max-width: 767px) {
            flex-direction: column;
            gap: 40px;
        }

        p {
            flex: 1;
            font-size: 0.875rem;
            line-height: 22px;
            color: #707070;
            max-width: 680px;
        }

        button {
            width: 196px;

            @media screen and (max-width: 767px) {
                width: 100%;
            }
        }
    }
}

// POPOVER CODES