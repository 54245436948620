h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 3.75rem;
    font-style: normal;
    font-weight: $fontWeight400;
    color: $color-system-heading;
}

h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 2rem;
    font-style: normal;
    font-weight: $fontWeight600;
    color: $color-system-heading;
}

h3 {
    font-family: 'Poppins', sans-serif;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: $fontWeight600;
    color: $color-system-heading;
}

h4 {
    font-family: 'Poppins', sans-serif;
    font-size: 1.625rem;
    font-style: normal;
    font-weight: $fontWeight500;
    color: $color-system-heading;
}

h5 {
    font-family: 'Poppins', sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: $fontWeight600;
    color: $color-system-black;
}

h6 {
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: $fontWeight600;
    color: $color-system-heading;
}

p,
li,
span,
strong,
em {
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    line-height: 1.25rem;
    text-transform: none;
}

a {
    font-size: 1rem;
    line-height: 1.3;
    font-weight: 400;
    font-style: normal;
    color: $color-system-blue;
    text-decoration: none;
}

.body-default-copy {
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    line-height: 25px;
    text-transform: none;

    @media screen and (max-width: 1100px) {
        font-size: 0.875rem;
    }
}

.body-default-copy-large {
    font-family: 'Poppins', sans-serif;
    font-size: 1.125rem;
    line-height: 27px;
    text-transform: none;

    @media screen and (max-width: 1100px) {
        font-size: 1rem;
    }

    strong,
    b,
    a {
        font-size: 1.125rem;
        line-height: 27px;
    }
}

.body-default-copy-medium {
    font-family: 'Poppins', sans-serif;
    font-size: 0.875rem;
    line-height: 23px;
    text-transform: none;
}

.body-default-copy-small {
    font-family: 'Poppins', sans-serif;
    font-size: 0.75rem;
    line-height: 21px;
    text-transform: none;
}