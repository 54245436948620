.MuiAlert-root {
    align-items: center;
    padding: 4px 8px !important;
    border-radius: 8px !important;
    margin-bottom: 18px;
    &.error {
        background: rgba(233, 69, 69, 0.05);
    }
    .MuiAlert-icon {
        svg {
            color: $color-system-validation-error;
        }
    }
    .MuiAlert-message {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.03em;
        color: $color-system-validation-error;
    }
}
