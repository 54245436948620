* {
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    white-space: inherit;
    word-wrap: break-word;
    box-sizing: border-box;
}


body,
form,
input,
select,
textarea,
button,
label,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: $color-system-black;
}

img {
    max-width: 100%;
}

body::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 12px;
    height: 12px;
}

::-webkit-scrollbar-thumb {
    background-color: #333333;
    border-radius: 20px;
    border: 2px solid #ececec;
}