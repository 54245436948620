footer {
    // position: fixed;
    // bottom: 0;
    // z-index: 1;
    width: 100%;
    padding-inline: 32px;
    padding-block: 0.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid rgb(1, 35, 72, 0.32);
    @media screen and (max-width: 1400px) {
        padding-inline: 16px;
    }
    @media screen and (max-width: 1100px) {
        flex-direction: column;
    }
    .footerLeft {
        display: flex;
        align-items: center;
        gap: 66px;
        @media screen and (max-width: 1100px) {
            flex-direction: column;
            gap: 8px;
        }
        ul {
            display: flex;
            align-items: center;
            gap: 66px;
            @media screen and (max-width: 1400px) {
                gap: 36px;
            }
            @media screen and (max-width: 1100px) {
                gap: 8px;
            }
            li {
                padding: 0 2px;
                a {
                    font-size: 0.875rem;
                    line-height: 20px;
                    color: $color-system-black;
                    @media screen and (max-width: 1100px) {
                        font-size: 9px;
                    }
                }
                .socialLinks {
                    display: flex;
                    flex-direction: row;
                    gap: 16px;
                }
                &.active {
                    border-bottom: 2px solid $color-system-red-primary;
                }
            }
        }
    }
    .footerRight {
        display: flex;
        align-items: center;
        span {
            font-size: 0.875rem;
            line-height: 20px;
            color: $color-system-black;
            @media screen and (max-width: 1100px) {
                font-size: 9px;
            }
        }
        img {
            object-fit: fill;
        }
    }
}

.noFooter {
    padding-bottom: 8px;
    footer {
        background-color: $color-system-white;
        display: none;
        @media screen and (max-width: 1100px) {
            padding-bottom: 8px;
        }
    }
}