.popover {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #012348;
    border-radius: 6px;
    opacity: 1;
    position: absolute;
    // top: 0px;
    // left: 0px;
    min-width: 200px;
    width: auto;
    z-index: 10000;


    &-head {
        background: #282D32 0% 0% no-repeat padding-box;
        border-radius: 6px 6px 0px 0px;
        opacity: 1;        
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
        letter-spacing: 0px;
        color: #FFFFFF;
        padding: 10px 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-close {
            // position: absolute;
            // top: 12px;
            // right: 12px;
            cursor: pointer;
            padding: 5px 0px 0px 10px;

            .fa-xmark {
                color: #FFFFFF;
                font-size: 20px;
            }
        }
    }

    &-body {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        opacity: 1;
        padding: 10px 16px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }
}