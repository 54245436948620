.noMarginForm {
    .MuiFormControl-root {
        margin-top: 0;
    }
}

.name2Column {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}

.textField {
    margin-bottom: 20px;

    input {
        width: 100%;
        padding: 5.5px 20px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid rgba(112, 112, 112, 0.41);
        font-size: 0.875rem;
        color: $color-system-black;
        border-radius: 4px;
        text-align:center;
        &::placeholder {
            font-size: 0.875rem;
            color: #707070;
        }
    }
}

.textarea {
    margin-bottom: 20px;

    textarea {
        width: 100%;
        padding: 7px 20px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid rgba(112, 112, 112, 0.41);
        font-size: 0.875rem;
        color: $color-system-black;
        border-radius: 4px;
        text-align:center;
        &::placeholder {
            font-size: 0.875rem;
            color: #707070;
        }
    }
}

.radioItems,
.checkBoxItems {

    .checkBox,
    .radioBox {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        padding: 10px 16px;
        border: 0.8px solid #DFDFDF;
        border-radius: 5px;

        label {
            font-size: 1rem;
        }

        input[type="radio"],
        input[type="checkbox"] {
            position: absolute;
            visibility: hidden;
        }

        input[type="radio"]+label,
        input[type="checkbox"]+label {
            position: relative;
            overflow: hidden;
            cursor: pointer;
        }

        input[type="radio"]+label::before,
        input[type="checkbox"]+label::before {
            content: "";
            display: inline-block;
            vertical-align: middle;
            height: 23px;
            width: 23px;
            background-color: $color-system-white;
            border: 1px solid #707070;
            border-radius: 5px;
            margin-right: 22px;
        }

        input[type="radio"]:checked+label::before {
            background: $color-system-pink;
            content: '\2714';
            color: $color-system-white;
            display: inline-block;
            vertical-align: middle;
            text-align: center;
            font-size: 12px;
            line-height: 21px;
            border: 1px solid $color-system-pink;
        }

        input[type="radio"]+label::before {
            border-radius: 50%;
        }

        input[type="checkbox"]+label {
            padding-left: 3em;
        }

        input[type="checkbox"]+label::before {
            width: 26px;
            height: 23px;
            border-radius: 5px;
            margin-left: -3em;
        }

        input[type="checkbox"]:checked+label::before {
            background: #D46B6A;
            border: 1px solid #D46B6A;
        }

        input[type="checkbox"]:checked+label::after {
            content: '\2713';
            position: absolute;
            color: $color-system-white;
            top: 2px;
            left: 8px;

        }
    }

    .indeterminate {
        input[type="checkbox"]:checked+label::before {
            background: #D46B6A;
            border: 1px solid #D46B6A;
        }

        input[type="checkbox"]:checked+label::after {
            content: "\002d";
            position: absolute;
            color: $color-system-white;
            top: 2px;
            left: 10px;

        }
    }

    .checkBoxItemCont {
        padding-left: 26px;
    }
}

.switchItem {
    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
    }

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked+.slider {
        background-color: $color-system-green;
    }

    input:focus+.slider {
        box-shadow: 0 0 1px $color-system-green;
    }

    input:checked+.slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
}