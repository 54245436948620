.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    padding: 10px 40px;
    outline: 0;
    border: 0;
    border-radius: 6px;
    cursor: pointer;
    vertical-align: middle;
    font-family: 'Poppins', sans-serif;
    font-size: 0.875rem;
    line-height: 1.75;
    font-weight: $fontWeight600;
    min-width: 64px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transform: translate3d(0, 0, 0);

    &::after {
        content: "";
        display: block;
        position: absolute;
        // width: 100%;
        // height: 100%;
        top: 0;
        left: 0;
        pointer-events: none;
        background-image: radial-gradient(circle, $color-system-white 10%, transparent 10.01%);
        background-repeat: no-repeat;
        background-position: 50%;
        transform: scale(10, 10);
        opacity: 0;
        transition: transform .5s, opacity 1s;
    }

    &:active::after {
        transform: scale(0, 0);
        opacity: .3;
        transition: 0s;
    }

    /* Disabled */

    &:disabled {
        color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
        background-color: transparent;
        cursor: initial;
    }

    &:disabled::before {
        opacity: 0;
    }

    &:disabled::after {
        opacity: 0;
    }
}

.btn-small {
    padding: 9.25px 16px;
}

.btn-blue {
    @extend .btn;
    background-color: $color-system-blue;
    color: $color-system-white;
    border: 1px solid $color-system-blue;

    &:hover,
    &:focus {
        background-color: $color-system-red-primary;
        border: 1px solid $color-system-red-primary;
    }
}

.btn-red {
    @extend .btn;
    background-color: $color-system-red-primary;
    color: $color-system-white;
    border: 1px solid $color-system-red-primary;

    &:hover,
    &:focus {
        background-color: $color-system-blue;
        border: 1px solid $color-system-blue;
    }
}
.btn-red-transparent {
    @extend .btn;
    background-color: $color-system-red-primary;
    color: $color-system-white;
    border: 1px solid $color-system-red-primary;

    &:hover,
    &:focus {
        background-color: transparent;
        color: $color-system-heading;
        border: 1px solid $color-system-red-primary;
    }
}

.btn-green {
    @extend .btn;
    background-color: $color-system-green;
    color: $color-system-white;
    border: 1px solid $color-system-green;

    &:hover,
    &:focus {
        background-color: $color-system-red-primary;
        border: 1px solid $color-system-red-primary;
    }
}

.btn-pink {
    @extend .btn;
    background-color: $color-system-pink;
    color: $color-system-white;
    border: 1px solid $color-system-pink;

    &:hover,
    &:focus {
        background-color: $color-system-green;
        border: 1px solid $color-system-green;
    }
}

.btn-grey {
    @extend .btn;
    background-color: $color-system-grey;
    color: $color-system-white;
    border: 1px solid $color-system-grey;
}

.btn-green-outlined {
    @extend .btn;
    background-color: $color-system-white;
    border: 1.5px solid $color-system-green-dark;
    color: $color-system-green-dark;
    font-weight: 600;

    &:hover,
    &:focus {
        background-color: $color-system-green;
        color: $color-system-white;
        border-color: $color-system-green
    }
}

.btn-blue-outlined {
    @extend .btn;
    background-color: $color-system-white;
    border: 1px solid $color-system-blue;
    color: $color-system-blue;
    font-weight: 400;

    &:hover,
    &:focus {
        background-color: $color-system-blue;
        color: $color-system-white;
        border-color: $color-system-blue
    }
}

.btn-red-outlined {
    @extend .btn;
    background-color: $color-system-white;
    border: 1.5px solid $color-system-red-primary;
    color: $color-system-red-primary;

    &:hover,
    &:focus {
        background-color: $color-system-red-primary;
        color: $color-system-white;
        border-color: $color-system-red-primary;
    }
}

.unStyledButton {
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.backBtn {
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    line-height: 25px;
    color: $color-system-green;
    text-transform: none;
    text-decoration: underline;
}

.btn-reset {
    border: 2px solid $color-system-heading;
    color: $color-system-heading
}